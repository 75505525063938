import {HttpClient, HttpResponseMessage} from "aurelia-http-client";
import {FhirService} from "./FhirService";
import {UserService} from "./UserService";
import {ISmileUser} from "../../views/admin/interfaces/ISmileUser";
import {ConfigService} from "./ConfigService";

export class SmileService {
    protected _baseUrl;
    protected _hash;

    constructor(baseUrl?: string, hash?: string) {
        this._baseUrl = baseUrl;
        this._hash = hash;

        if (ConfigService.Debug) {
            window["SmileService"] = this;
        }
    }

    public getClient(baseUrl?: string, hash?: string): HttpClient {
        const authorization = `Basic ${hash || this._hash || FhirService.Hash}`;

        let client = new HttpClient();
        client.configure(o => {
            o.withBaseUrl(baseUrl || this._baseUrl || FhirService.AdminEndpoint);
            o.withHeader('Accept', 'application/json');
            o.withHeader('Content-Type', 'application/json');
            o.withHeader('Authorization', authorization);
        });

        return client;
    }

    private async _getUsersPage(pageIndex, pageSize: number = 500): Promise<ISmileUser[]> {
        return new Promise(async (resolve, reject) => {
            const result: HttpResponseMessage = await this.getClient().get(`user-management/${FhirService.UserNodeId}/${FhirService.UserModuleId}?pageSize=${pageSize}&pageNum=${pageIndex}`);
            let users = <ISmileUser[]>JSON.parse(result.response).users;
            users = users.filter(o => o.username != "ADMIN" && o.username != "ROOT" && o.username != "ANONYMOUS" && o.username != "EMBEDDED"
                && String(o.moduleId).toUpperCase() === FhirService.UserModuleId.toUpperCase() && String(o.nodeId).toUpperCase() === FhirService.UserNodeId.toUpperCase()
            );

            resolve(users && users.length > 0 ? users : undefined);

            return;
        });
    }

    async getUsers(index = 0, pageSize: number = 500) {
        const users = [];
        let weiter = true;
        let pageIndex = 0;
        while (weiter) {
            if (weiter) {
                const tmpUsers = await this._getUsersPage(pageIndex, pageSize);
                if (tmpUsers) {
                    weiter = true;
                    users.push(...tmpUsers);
                    pageIndex++;
                } else weiter = false;
            }
        }

        return users;
    }

    getRemoteUser(userId) {
        return this.getClient(FhirService.RemoteSetup.fhirAdmin, UserService.AdminUsers.remote)
                .get(`user-management/${FhirService.UserNodeId}/${FhirService.UserModuleId}?searchTerm=${encodeURIComponent(userId)}`)
                .then((result: HttpResponseMessage) => {
                    return JSON.parse(result.response).users[0];
        });
    }

    getUser(username, fhirAdmin?: string, hash?: string) {
        return this.getClient(fhirAdmin, hash)
            .get(`user-management/${FhirService.UserNodeId}/${FhirService.UserModuleId}?searchTerm=${encodeURIComponent(username)}`)
            .then((result: HttpResponseMessage) => {
            let users = JSON.parse(result.response).users;
            return users.find(o => o.username.toUpperCase() === username.toUpperCase());
        });
    }

    async checkFhirHealth(fhirServer): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let url = `${fhirServer}/endpoint-health`;
            let client = new HttpClient();
            client.createRequest(url).withTimeout(5000).asGet().send()
                .then(result => {
                    let js = JSON.parse(result.response);
                    resolve(js.status && js.status.toUpperCase() === 'OPERATIONAL');
                })
                .catch(error => {
                    // statusCode 0 is fine, it means no connection/timeout occured.
                    if (typeof error.statusCode === "number" && error.statusCode === 0) {
                        resolve(false);
                    } else {
                        // this is bad - it means something else went wrong, so reject the promise.
                        reject(error);
                    }
                });
        });
    }

    async getPractitioner(fhirServer: string, hash: string, username: string) {
        try {
            let response = await this.getClient(fhirServer, hash).get(`Practitioner?identifier=${username.toUpperCase()}&_revinclude=PractitionerRole:practitioner`);
            let result = <any>JSON.parse(response.response);
            let roleEntry = result.entry.find(o => o.resource?.resourceType === 'PractitionerRole');
            let pracEntry = result.entry.find(o => o.resource?.resourceType === 'Practitioner');
            let role = roleEntry ? roleEntry.resource : undefined;
            let prac = pracEntry ? pracEntry.resource : undefined;
            return {role: role, practitioner: prac};
        } catch (e) {
            console.warn(e.message || JSON.stringify(e));
            return undefined;
        }
    }

    updateUser(data) {
        const dataCopy = JSON.parse(JSON.stringify(data));

        delete dataCopy.external;
        delete dataCopy.lastActive;
        delete dataCopy.moduleId;
        delete dataCopy.nodeId;
        delete dataCopy.pid;
        delete dataCopy.wards;

        return this.getClient().put(`user-management/${data.nodeId}/${data.moduleId}/${data.pid}`, dataCopy).then((result: HttpResponseMessage) => {
            return JSON.parse(result.response);
        }).catch((result: HttpResponseMessage) => {
            const response = result.response.match(/\[errors=\[\[field=([A-z]+),message=([A-z ."]+)\]\]\]/);

            if (response.length === 3) {
                return {
                    error: {
                        field: response[1],
                        message: response[2]
                    }
                };
            } else {
                return null;
            }
        });
    }

    async createUser(data, adminUrl?: string, hash?: string, userNodeId?: string, userModuleId?: string): Promise<any> {
        const dataCopy = JSON.parse(JSON.stringify(data));
        delete dataCopy.external;
        delete dataCopy.lastActive;
        delete dataCopy.moduleId;
        delete dataCopy.nodeId;
        delete dataCopy.pid;
        delete dataCopy.wards;

        try {
            const result: HttpResponseMessage = await this.getClient(adminUrl, hash)
                .post(`user-management/${userNodeId || FhirService.UserNodeId}/${userModuleId || FhirService.UserModuleId}`, dataCopy);
            return JSON.parse(result.response);
        } catch (result) {
            const response = result.response.match(/\[errors=\[\[field=([A-z]+),message=([A-z ."]+)\]\]\]/);
            if (response) {
                if (response.length === 3) {
                    return {
                        error: {
                            field: response[1],
                            message: response[2]
                        }
                    };
                } else {
                    return null;
                }
            } else {
                return {
                    error: {
                        field: 'error',
                        message: result.response
                    }
                };
            }
        }
    }

    deleteUser(data) {
        return this.getClient().delete(`user-management/${FhirService.UserNodeId}/${FhirService.UserModuleId}/${data.pid}`);
    }
}
